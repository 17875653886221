<template>
    <div style="background-color:#F5F5F5" class="p-2 border rounded">
        <div>
            <div class="accordion-body row pt-0">
                <ODataGrid :data-object="dsCDE" hideGridMenu disableFilterRow style="max-height:400px; min-height:100px"
                    disableDeleteConfirm newRecordsPosition="bottom">
                    <OColumn editable field="DocReference" headerName="Doc. Reference" width="150" sortable></OColumn>
                    <OColumn editable field="OriginalRequirements" width="250" headerName="Original requirements"> </OColumn>
                    <OColumn editable field="DeviationException" headerName="Deviation (D) or Exception (E)" width="150" sortable v-slot:editor="{modelValue: row}">
                        <ODataLookup :data-object="dsDevExLkp" :bind="sel => { row.DeviationException = sel.Type; }">
                            <template #target="{ target }">                        
                                <input :ref="target" :value="row.DeviationException">
                            </template>
                            <o-column field="Type" width="100"></o-column>
                        </ODataLookup>
                    </OColumn>
                    <OColumn editable field="Description" class="text-end" headerName="Description and/or reason for Deviation / Exception" width="80"></OColumn>
                    <OColumn editable field="AcceptReject" headerName="Accept or Reject" width="100" sortable v-slot:editor="{modelValue: row}">
                        <ODataLookup :data-object="dsAcceptRejectLkp" :bind="sel => { row.AcceptReject = sel.Status; }">
                            <template #target="{ target }">                        
                                <input :ref="target" :value="row.AcceptReject">
                            </template>
                            <o-column field="Status" width="100"></o-column>
                        </ODataLookup>
                    </OColumn>
                    <OColumn editable field="ReasonAcceptReject" class="text-end" headerName="Reason for acceptance or rejection" width="80"></OColumn>
                    <OColumn editable field="AcceptRejectDate" class="text-end" headerName="Accept or Reject Date" width="80" format="Short Date" v-slot:editor="{modelValue: row}">
                        <ODatePicker date format="Short Date" v-model="row.AcceptRejectDate" :clearable="false" clearableFromPicker class="form-control form-control-sm">
                        </ODatePicker>             
                    </OColumn>
                    <OColumn field="AcceptRejectBy" class="text-end" headerName="Accept or Reject By" :editable="false" width="80"></OColumn>
                </ODataGrid>    
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { useDataObjectEventListener } from 'o365.vue.composables.EventListener.ts'; 
    import OColumn from "o365.vue.components.DataGrid.Column.jsx"   
    import { defineProps } from 'vue';
    import { $t, utils } from 'o365-utils';
    
    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean
    });

    const showCDEDetails = ref(false);

    async function loadData() {
        dsCDE.recordSource.whereClause = "Item_ID = " + props.itemId;
        await dsCDE.load();
        console.log("Test:", dsCDE.current.DocReference)
    }
    
    function toggleCDEDetails() {
        showCDEDetails.value = !showCDEDetails.value;
    }

    const dsCDE = getOrCreateDataObject(
    {
        "id": "dsCDE",
        "viewName": "aviw_MSFT_CDE_DeviationAndExceptions",
        "uniqueTable": "atbv_MSFT_CDE_DeviationAndExceptions",
        "distinctRows": false,
        "distinctRows": false,
        "allowUpdate":  true,
        "allowInsert":  true,
        "allowDelete":  true,
        "appendData": true,
        "loadRecents": false,
        "dynamicLoading": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "Item_ID"},
            {"name": "ID"},
            {"name": "DocReference"},
            {"name": "OriginalRequirements"},
            {"name": "DeviationException"},
            {"name": "Description"},
            {"name": "AcceptReject"},      
            {"name": "ReasonAcceptReject"},       
            {"name": "AcceptRejectDate"},        
            {"name": "AcceptRejectBy"},         
            {"name": "PrimKey"},       
        ],
        "clientSideHandler": false,
        "maxRecords": 50,
        "dynamicLoading": false,
        "whereClause": `Item_ID=${props.itemId}`,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });
    
    const dsAcceptRejectLkp = getOrCreateDataObject({
        id: 'dsAcceptRejectLkp',
        viewName: 'aviw_MSFT_CDE_AcceptRejectLkp',
        maxRecords: -1,
        loadRecents: false,
        dynamicLoading: false,
        fields: [
            { name: "Status", type: "string" },
        ],
    });
    
    const dsDevExLkp = getOrCreateDataObject({
        id: 'dsDevExLkp',
        viewName: 'aviw_MSFT_CDE_DeviationExceptionLkp',
        maxRecords: -1,
        loadRecents: false,
        dynamicLoading: false,
        fields: [
            { name: "Type", type: "string" },
        ],
    });

    onMounted(() => {
        //loadData();
        dsCDE.load();

        useDataObjectEventListener( dsCDE, "FieldChanged", (fieldName) => {
            if (dsCDE.current.isNewRecord) {
                dsCDE.current.Item_ID = props.itemId;
            }
        });
    });
</script>